.signin {
    display: flex;
    background: transparent url('../../img/background/login-register.jpg') 0% 0% no-repeat padding-box;
    background-size: cover;
    height: 100%;
    width:auto;
    .cover {
        max-width: 480px;
        width: 100%;
        background: transparent url('../../img/background/truck.png') 0% 0% no-repeat padding-box;
        background-size: cover;
        position: relative;
        @include respond-below('lg') {
            display: none;
        }
        &__text {
            position: absolute;
            margin: 70px 45px;
            color: white;
            font-size: 40px;
            line-height: 51px;
            letter-spacing: 0;
            font-weight: bold;
            bottom: 0;
        }
    }
    .login {
        width: 100%;
        display: flex;
        flex-direction: column;
        .form-title {
            text-align: left !important;
            font-size: 20px;
            color: black;
            margin: 16px 0;
            text-align: center;
            padding: 0px;
        }
        .form-title-inner {
            text-align: left !important;
            font-size: 16px;
            color: black;
            margin: 16px 0;
            text-align: center;
            padding: 0px;
        }
        .form-title-bg {
            padding: 20px;
            background-color: rgba(201, 201, 201, 0.514);
            border-radius: 10px;
            margin: 16px 0;
            }
        .form-description {
            font-size: 18px;
            text-align: left;
            margin: 16px 0;
            color: black;
        }
        .form-wrapper {
            max-width: 440px;
            margin: 0 auto;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
        }
        .logo{
            padding-bottom: 20px;
            text-align: center;
            margin: 30px 0 0px;
        }
        .signup-acc {
            text-align: center;
            padding: 20px;

        }
    }
   
}

.resetPass {
    font-size: 14px;
    color: #2e86aa;
    cursor: pointer;
}